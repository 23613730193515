exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-switsbake-js": () => import("./../../../src/pages/om-switsbake.js" /* webpackChunkName: "component---src-pages-om-switsbake-js" */),
  "component---src-pages-sortiment-js": () => import("./../../../src/pages/sortiment.js" /* webpackChunkName: "component---src-pages-sortiment-js" */),
  "component---src-templates-foodservice-trademark-page-js": () => import("./../../../src/templates/foodservice-trademark-page.js" /* webpackChunkName: "component---src-templates-foodservice-trademark-page-js" */),
  "component---src-templates-foodservice-trademarks-page-js": () => import("./../../../src/templates/foodservice-trademarks-page.js" /* webpackChunkName: "component---src-templates-foodservice-trademarks-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

