module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Switsbake","short_name":"Switsbake","start_url":"/","background_color":"#f3f3f3","theme_color":"#2b2b2b","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"458e13f02f4032b4595c4efb54e641a0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","css","map","png"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://switsbake.se"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":14,"matomoUrl":"https://matomo.accodeing.com","siteUrl":"https://switsbake.se"},
    },{
      plugin: require('../node_modules/@accodeing/gatsby-theme-heimr/gatsby-browser.js'),
      options: {"plugins":[],"manifest":{"name":"Switsbake","short_name":"Switsbake","background_color":"#f3f3f3","theme_color":"#2b2b2b"},"siteUrl":"https://switsbake.se","matomoSiteId":14},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Hem","exclude":["/404","/404.html"],"useClassNames":true,"crumbLabelUpdates":[{"pathname":"/sortiment","crumbLabel":"Sortiment"},{"pathname":"/sortiment/foodservice","crumbLabel":"Foodservice"},{"pathname":"/sortiment/foodservice/cakes4u","crumbLabel":"Cakes4U"},{"pathname":"/sortiment/foodservice/dancake","crumbLabel":"Dan Cake"},{"pathname":"/sortiment/foodservice/almondy","crumbLabel":"Almondy"},{"pathname":"/sortiment/foodservice/sweetdelight","crumbLabel":"Sweet Delight"},{"pathname":"/sortiment/foodservice/cocandy","crumbLabel":"Cocandy"},{"pathname":"/sortiment/foodservice/humlum","crumbLabel":"Humlum"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
